import React from 'react'
import Layout from "../components/layout"
import { Container, Row } from 'reactstrap'
import Link from '../components/link'
import styled from 'styled-components'
import useSiteMetadata from '../hooks/use-site-config'
import { Helmet } from "react-helmet"

const Text404 = styled.div`
font-size: x-large;
    width: 50%;
    margin: 4rem auto;
`
const Link404 = styled(Link)`
color: #932a7e;
font-weight: 700;
`

export default function PageNotFound(props) {
  const { siteTitle } = useSiteMetadata()
  const title="Disculpe, no se encontró la página que busca | "+ siteTitle
  return (
    <Layout>
    <Helmet title={title}>
          <meta charSet="utf-8" />
        
        </Helmet>
      <Container>
        <Row>
          <Text404>
          <p>Disculpe, no se encontró la página que busca. </p>
          <Link404 to={"/"} className="link">Volver al Inicio</Link404>
          </Text404>
        </Row>
      </Container>
    </Layout>
  );
}

