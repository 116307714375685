import React, { Component } from 'react';
import "../assets/stylesheets/headerExternal.scss"
import Scroll from './Scroll';
import SocialLinks from './SocialLinks';
import styled from 'styled-components'
import { GatsbyImage } from "gatsby-plugin-image";

const Brand = styled(GatsbyImage)`
width: 40px;
height: 40px;
display: block;
`

export default class Header extends Component {
  constructor(props) {
    
    super(props);
    this.state = {
      openMenu: false,
      visibilityClass: '',
      head: props.headImage,
    };
    
  }
  toggleMenu = value => {
    this.setState({ openMenu: value });
  };
  handleScroll = () => {
    const { visibilityClass } = this.state;
    if (window.pageYOffset > 100) {
      if (visibilityClass !== 'navbar-shrink') {
        this.setState({ visibilityClass: 'navbar-shrink' });
      }
    } else {
      if (visibilityClass === 'navbar-shrink') {
        this.setState({ visibilityClass: '' });
      }
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { openMenu, visibilityClass,head } = this.state;
    return (
      
      <nav
        className={`navbar navbar-expand-lg navbar-light fixed-top ${visibilityClass} main`}
        id="mainNav"
      >
        <div className="container main">
        <Scroll
        onClick={_ => this.toggleMenu(!openMenu)}
        type="id"
        element="top">        
          <a className="navbar-brand" href="#top">
            <Brand image={head}/>
          </a>
        </Scroll>
       
          <button
            onClick={_ => this.toggleMenu(!openMenu)}
            className={`navbar-toggler navbar-toggler-right ${
              openMenu ? '' : 'collapsed'
            }`}
            type="button"
            aria-controls="navbarResponsive"
            aria-expanded={openMenu}
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars"></i>
          </button>
          <div
            className={`collapse navbar-collapse ${openMenu ? 'show' : ''}`}
            id="navbarResponsive"
          >
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Scroll
                  onClick={_ => this.toggleMenu(!openMenu)}
                  type="id"
                  element="about"
                >
                  <a className="nav-link" href="#about">
                    QUIÉNES SOMOS
                  </a>
                </Scroll>
              </li>                               
              <li className="nav-item">
                <Scroll
                  onClick={_ => this.toggleMenu(!openMenu)}
                  type="id"
                  element="services"
                  offset={-100}
                >
                  <a className="nav-link" href="#services">
                    SERVICIOS
                  </a>
                </Scroll>
              </li>
              <li className="nav-item">
              <Scroll
                  onClick={_ => this.toggleMenu(!openMenu)}
                  type="id"
                  element="products"
                  offset={-100}
                >
                  <a className="nav-link" href="#products">
                    PRODUCTOS
                  </a>
                </Scroll>
              </li>
              <li className="nav-item">
                <Scroll
                  onClick={_ => this.toggleMenu(!openMenu)}
                  type="id"
                  element="works"
                  offset={-100}
                >
                  <a className="nav-link" href="#works">
                    TRABAJOS
                  </a>
             
                </Scroll>
              </li>
              <li className="nav-item">
                <Scroll
                  onClick={_ => this.toggleMenu(!openMenu)}
                  type="id"
                  element="jobs"
                  offset={-100}
                >
                  <a className="nav-link" href="#jobs">
                    OPORTUNIDADES
                  </a>
             
                </Scroll>
              </li>
              <li className="nav-item">
                <Scroll
                  onClick={_ => this.toggleMenu(!openMenu)}
                  type="id"
                  element="contact"
                  offset={-40}
                >
                  <a className="nav-link" href="#contact">
                    CONTACTO
                  </a>
             
                </Scroll>
              </li>
            </ul>
            {/* <SocialLinks className="social-links"/> */}
          </div>
        </div>
      </nav>
    );
  }
}
