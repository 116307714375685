import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import "../assets/stylesheets/layout.scss"
import {ThemeProvider} from 'styled-components'
import theme from '../assets/stylesheets/theme'
import { Helmet } from "react-helmet"
import { getImage } from "gatsby-plugin-image";


const Layout = ({ children, className}) => {

  const data = useStaticQuery(graphql`query SiteTitleQuery {
  site {
    siteMetadata {
      title
    }
  }
  head: file(relativePath: {eq: "assets/images/logohead.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
    }
  }
}
`)

  return (
    <ThemeProvider theme={theme}>
      <Helmet  title={data.site.siteMetadata.title}>
       <meta name="description" content="Stacktrace" />
       <meta charSet="utf-8" />
      </Helmet>
      <Header headImage={getImage(data.head.childImageSharp)}/>
        <section id="top" ></section>
        <main >{children}</main>
      <Footer  className={className}/>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
